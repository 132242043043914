import {
  type AssetEntity,
  type ContentBlockEntity,
  type DeviceType,
  isAsset,
  isExpanded,
} from "~/src/api";
import { INDEX_DEVICE_TYPE } from "~/src/content/static";

const deviceTypeOrder: DeviceType[] = ["web", "tablet", "mobile", "none"];
export const sortByDeviceType = (a: AssetEntity, b: AssetEntity) =>
  deviceTypeOrder.indexOf(a.attributes.deviceType) -
  deviceTypeOrder.indexOf(b.attributes.deviceType);

// gets the ordered array of assets
export const getContentBlockAssets = (block: ContentBlockEntity) => {
  const assetMap: Record<string, AssetEntity> = Object.fromEntries(
    block.relationships
      .filter(isAsset)
      .filter(isExpanded)
      .map((rel) => [[rel.id], { ...rel, relationships: [] }]),
  );

  return block.attributes.assetOrder
    .map((id) => assetMap[id])
    .filter(onlyTruthys);
};

export const getATFBannerGroups = (block: ContentBlockEntity) =>
  groupATFAssets(getContentBlockAssets(block));
export const getATFBannerBackgrounds = (block: ContentBlockEntity) =>
  getContentBlockAssets(block).filter(
    (asset) =>
      asset.attributes.deviceType === "none" &&
      asset.attributes.parameters?.global,
  );

export const groupATFAssets = (assets: AssetEntity[]) => {
  const resultArr: { id: string; assets: AssetEntity[] }[] = [];

  // start at -1 because we increment before adding
  let index = -1;
  assets
    // remove pins
    .filter((asset) => !asset.attributes.parameters?.pinContent)
    // remove global backgrounds
    .filter((asset) => !asset.attributes.parameters?.global)
    .forEach((asset) => {
      if (asset.attributes.deviceType === INDEX_DEVICE_TYPE) {
        index += 1;
        resultArr[index] = {
          id: asset.id,
          assets: [asset],
        };
      } else resultArr[index]?.assets.push(asset);
    });

  resultArr.forEach((group) => {
    group.assets = group.assets.sort(sortByDeviceType);
  });

  return resultArr;
};

export const getATFBannerPins = (assets: AssetEntity[]) => {
  return {
    "web.top":
      assets.find(
        (asset) =>
          asset.attributes.parameters?.pinContent === "top" &&
          asset.attributes.deviceType === "web",
      ) || null,
    "web.bottom":
      assets.find(
        (asset) =>
          asset.attributes.parameters?.pinContent === "bottom" &&
          asset.attributes.deviceType === "web",
      ) || null,
    "tablet.top":
      assets.find(
        (asset) =>
          asset.attributes.parameters?.pinContent === "top" &&
          asset.attributes.deviceType === "tablet",
      ) || null,
    "tablet.bottom":
      assets.find(
        (asset) =>
          asset.attributes.parameters?.pinContent === "bottom" &&
          asset.attributes.deviceType === "tablet",
      ) || null,
  };
};
